$fontFamily: 'myriad-pro', sans-serif;
$bold: 600;
$semiBold: 400;
$regular: 300;
$extraLight: 200;

$themeColor: #A88E6A;
$themeColor2: #212931;
$textColor: #4C5F88;
$lightTextColor: #83898F;
$lightColor: #ECF1FD;
$veryLightColor: #F5F5F5;
$veryLightBGColor: #F4F6F6;
$darkColor: #212931;
$borderColor: #E2E2E2;
$lightBorderColor: #EBEBEB;

//media queries
$tabletMax: 767px;
$tabletMin: 768px;
$laptopMax: 991px;
$laptopMin: 992px;
$desktopMax: 1199px;
$desktopMin: 1200px;

/*Myriad Pro Boldfont-family: myriad-pro, sans-serif;
font-weight: 700;
font-style: normal;Myriad Pro Bold Italicfont-family: myriad-pro, sans-serif;
font-weight: 700;
font-style: italic;Myriad Pro Italicfont-family: myriad-pro, sans-serif;
font-weight: 400;
font-style: italic;Myriad Pro Lightfont-family: myriad-pro, sans-serif;
font-weight: 300;
font-style: normal;Myriad Pro Light Italicfont-family: myriad-pro, sans-serif;
font-weight: 300;
font-style: italic;Myriad Pro Regularfont-family: myriad-pro, sans-serif;
font-weight: 400;
font-style: normal;Myriad Pro Semiboldfont-family: myriad-pro, sans-serif;
font-weight: 600;
font-style: normal;Myriad Pro Semibold Italicfont-family: myriad-pro, sans-serif;
font-weight: 600;
font-style: italic;*/