.clearfix{
    clear: both;
}
.mb_mobile{
    @media(min-width: $laptopMin){
        display: none!important;
    }
}
.mb_mobile-tablet{
    @media(min-width: $tabletMin){
        display: none!important;
    }
}
.mb_widescreen{
    display: none;
    @media(min-width: $laptopMin){
        display: block;
    }
}

.mb_tablet{
    @media(max-width: $tabletMax){
        display: none!important;
    }
}

.mb_hover-dropshadow{
    @include transition(0.25s);
    @include delay(0.15s);
    &:hover{
        @include dropshadow;
    }
}

.mb_center-text{
    text-align: center;
}

.mb_border{
    border: 1px solid $lightBorderColor;
}

.mb_border-bottom-theme-color{
    border-bottom: 1px solid $themeColor;
}

.mb_border-bottom{
    border-bottom: 1px solid $borderColor;
}

.mb_border-bottom-light{
    border-bottom: 1px solid $lightBorderColor;
}

.mb_border-bottom-dashed{
    border-bottom: 1px dashed $lightBorderColor;
}

.mb_border-top{
    border-top: 1px solid $lightBorderColor;
}

.mb_border-bottom-center{
    border-bottom:2px solid $themeColor;
    width: 80px;
    margin: 0 auto 20px auto;
}

.mb_border-bottom-left{
    border-bottom:1px solid $themeColor;
    width: 80px;
    margin-bottom: 20px;
}

.mb_overlay-dark{
    /*background-color: $themeColor2;
    opacity: 0.9;*/
    width: 100%;
    height: 100%;
}

.mb_margin-top{
    margin-top: 45px;
}

.mb_margin-bottom{
    margin-bottom: 50px !important;
}

._emOp-nav-link{
    display: flex;
    line-height: 1;
    text-align: center;
    align-items: center;
}