body{
    font-family: $fontFamily;
    font-weight: $regular;
    letter-spacing: 0.3px;
}

h1, h2, h3, h4{
    line-height: 1.5;
}

a{
    text-decoration: none;
    display: inline-block;
    &:hover{
        text-decoration: none;
    }
}

li{
    list-style: none;
}

img {
    pointer-events: none;
}

figure{
    width: 100%;
    margin: 0;
    img{
        width: 100%;
    }
}

section{
    padding: 20px 0 100px 0;
    @media(min-width: $tabletMin){
        padding: 20px 0 100px 0;
    }
    @media(min-width: $laptopMin){
        padding: 20px 0 100px 0;
    }
}