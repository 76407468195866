.mb_benefits-section{
    padding: 15px 0 80px 0;
    .mb_benefits-section-row{
        padding: 15px 0;
        line-height: 1.3;
        min-height: 140px;
        .col-9{
            display: flex;
            flex-wrap: wrap;
            align-content:center;
        }
        .mb_heading-3-1{
            margin-bottom: 5px;
            line-height: 1.3;
        }
        @media(min-width: $tabletMin){
            .col-3{
                text-align: center;
                display: flex;
                align-items: center;
            }
            img{
                @include transition(0.25s);
                width: 100px;
            }
            &:hover{
                img{
                    width: 105px;
                }
             }
        }
    }
    .mb_button{
        margin: 45px auto 20px auto;
    }
    .mb_center-text{
        margin-top: 45px;
        img{
            width: 220px;
            margin-bottom: 10px;
        }
    }
}

.mb_news-home-section{
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 0;
    .mb_overlay-dark{
        padding: 100px 0;
        .mb_read-more-link{
            color: white;
        }
        .mb_button-white{
            margin: 15px auto 0 auto;
        }
        .mb_border-bottom-center{
            margin: 0 auto 60px auto;
        }
    }
    .mb_news-item{
        margin-bottom: 60px;
        .mb_heading-3-1{
            margin-bottom: 5px;
        }
    }
}

.mb_news-list-section{
    padding: 20px 0 40px 0;
    .mb_search-results{
        padding-bottom: 30px;
    }
    .mb_news-item{
        margin-bottom: 60px;
        .mb_heading-3-1{
            margin-bottom: 5px;
        }
        img{
            width: 100%;
        }
    }
}
.mb_news-article-section{
    padding: 20px 0 80px 0;
    .container{
        max-width: 770px;
    }
    .mb_image-center{
        margin-bottom: 30px;
    }
    .mb_button{
        min-width: 140px;
        margin: 30px auto 20px auto;
    }
}

.mb_event-section{
    padding: 20px 0 40px 0;
}

.mb_event-section-date{
    padding: 20px 0 0 0;
    span{
        font-weight: $bold;
    }
}

.mb_event-table-section{
    padding: 20px 0 80px 0;
    .mb_table{
        text-align: left;
        max-width: 770px;
        margin: 0 auto 60px auto;
        th{
            font-size: 20px;
            .mb_border-bottom-theme-color{
                margin: 10px 0;
            }
        }
    }
    .mb_button{
        min-width: 140px;
        margin: 45px auto 20px auto;
    }
}

.mb_training-skills-section{
	padding: 15px 0 70px 0;
    .mb_button{
        margin: 30px auto 30px auto;
    }
    .mb_border-bottom-center{
        margin: 0 auto 60px auto;
    }
    .col-xl-3{
        padding-bottom: 30px;
    }
    .mb_training-skills-item{
        padding: 15px;
        border-radius: 5px;
        height: 100%;
        .mb_training-skills-item-content{
            border: 2px solid #6e8cca;
            padding: 5px;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content:space-between;
            .mb_training-skills-item-content-top{
                text-align: center;
                width: 100%;
                img{
                    max-width: 90px;
                    margin-bottom: 10px
                }
                h5{
                    font-weight: $bold;
                }
                h6{
                    font-weight: $bold;
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                }
            }
            article{
                text-align: center;
                width: 100%;
                min-height: 40px;
                text-transform: uppercase;
                font-weight: $semiBold;
                font-size: 14px;
            }
            .mb_training-skills-item-content-bottom{
                text-align: center;
                width: 100%;
                .mb_paragraph-8{
                    margin-bottom: 0;
                }
            }
        }

    }
    .mb_pagination{
        margin: 40px 0;
    }
}

.mb_socialfeed-section{
	padding: 100px 0;
    background-color: $veryLightColor;
    .mb_border-bottom-center{
        margin: 0 auto 60px auto;
    }
    .mb_button{
        margin: 15px auto 0 auto;
    }
    .mb_social-content{
        padding: 20px;
        .mb_heading-7{
            margin-bottom: 30px;
        }
        .mb_socialfeed-source{
            display: flex;
            justify-content: space-between;
            .mb_socialfeed-source-title-logo{
                display: flex;
                .mb_socialfeed-logo{
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
                .mb_paragraph-3{
                    
                }
            }
            .mb_socialfeed-icon{
                width: 12px;
            }
        }
    }
}

.mb_about-section{
    padding: 20px 0 60px 0;
    .mb_email{
        color: $themeColor;
    }
}

.mb_about-benefits-section{
    .mb_about-benefits{
        display: flex;
        margin-bottom: 40px;
    }
    .mb_about-benefits-sub-heading{
        text-align: center;
        margin: 50px auto;
        max-width: 870px;
    }
    .mb_button{
        margin: 0 auto;
    }
}

.mb_about-code-of-practice-section{
    padding: 20px 0 60px 0;
}

.mb_corporate-social-responsibily-section{
    padding: 20px 0 40px 0;
}

.mb_management-section{
    padding: 20px 0 40px 0;
    .mb_heading-3-1{
        text-transform: uppercase;
    }
    table, th, td {
      border: 1px solid $lightBorderColor;
      line-height: 1.3;
      vertical-align: middle;
      a{
        color: #212931;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
      }
    }
    table{
        /*width: 100%;*/
        min-width: 774px;
        margin-bottom: 60px;
        th{
            font-size: 14px;
            font-weight: $bold;
            color: white;
            padding: 10px 15px;
            height: 50px;
            /*width: 33.33333%;*/
            width: 25%;
            background-color: $themeColor;
            text-transform: uppercase;
            @media(min-width: $tabletMin){
                font-size: 17px;
                padding: 10px 30px;
                height: 70px
            }
        }
        td{
            font-size: 12px;
            color: $darkColor;
            padding: 10px 15px;
            height: 50px;
            /*width: 33.33333%;*/
            width: 25%;
            @media(min-width: $tabletMin){
                font-size: 16px;
                padding: 10px 30px;
                height: 70px;
            }
        }
    }
    .mb_management-tables{
        tr:first-child{
            th{
                font-size: 14px;
                font-weight: $bold;
                color: white;
                padding: 10px 15px;
                height: 50px;
                /*width: 33.33333%;*/
                width: 25%;
                background-color: $themeColor;
                text-transform: uppercase;
                @media(min-width: $tabletMin){
                    font-size: 17px;
                    padding: 10px 30px;
                    height: 70px
                }
            }
        }
        th{
            color: white;
            height: 50px;
            /*width: 33.33333%;*/
            width: 25%;
            background-color: white;
            @media(min-width: $tabletMin){
                height: 70px
            }
        }
    }
}

.mb_staff-section{
    table{
        width: 100%;
        margin-bottom: 60px;
        line-height: 1.3;
        th{
            font-size: 14px;
            font-weight: $bold;
            color: $darkColor;
            width: 33.33333%;
            padding: 0 15px;
            @media(min-width: $tabletMin){
                font-size: 20px;
                padding: 0 15px;
            }
            .mb_border-bottom-theme-color{
                 margin: 10px 0 10px 0;
                @media(min-width: $tabletMin){
                    margin: 10px 0 15px 0;
                }
            }
        }
        td{
            font-size: 12px;
            color: $lightTextColor;
            width: 33.33333%;
            padding: 5px 15px;
            @media(min-width: $tabletMin){
                font-size: 16px;
                padding: 6px 15px;
            }
            a:hover{
                color: $lightTextColor;
            }
        }
    }
    .mb_image-center{
        text-align: center;
        img{
            max-width: 770px;
        }
    }
}

.mb_round-image-item{
    text-align: center;
    margin-bottom: 60px;
    // @include transition(0.15s); 
    // &:hover{
    //     transform: scale(1.05)
    // }
    img{
        @include transition(0.25s);
        width: 150px;
        height: auto;
        border: 0.5px solid #C3C3C3;
        margin: 5px 0;
    }
    .mb_heading-3-1{
        margin: 0;
    }
    .mb_paragraph-3:hover{
        color: $lightTextColor;
    }
}

.mb_apply-membership-page-section{
    .container{
        max-width: 770px;
    }
}

.mb_search-members-page-section{
    padding: 20px 0 30px 0;
    /*padding: 20px 0 80px 0;*/
    /*.mb_button{
        margin: 0 30px 30px 0;
    }*/
}

.mb_search-results-page-section{
    padding: 20px 0 20px 0;
}

.mb_search-members-table-section{
    h4{
        margin-bottom: 30px;
    }
    table, th {
      border: 1px solid $lightBorderColor;
    }
    table{
        width: 100%;
        margin-bottom: 60px;
        line-height: 1.3;
        th{
            vertical-align: middle;
            font-size: 20px;
            font-weight: $bold;
            color: $darkColor;
            padding: 15px;
            padding: 0;
            background-color: $veryLightBGColor;
            @media(min-width: $tabletMin){
                font-size: 25px;
                padding: 30px;
            }
        }
        td{
            vertical-align: middle;
            font-size: 12px;
            color: $darkColor;
            padding: 0;
            height: 50px;
            border-top: 1px solid $lightBorderColor;
            @media(min-width: $tabletMin){
                font-size: 16px;
                height: 70px;
            }
        }
        a{
            text-decoration: none;
            color: $darkColor;
            &:hover{
                color: $darkColor;
            }
        }
    }
    .mb_table-inner-row{
        display: inline-table;
        width: 100%;
        border: none;
        td{
            border-right: 1px solid $lightBorderColor;
        }
    }
    .mb_padding-td{
        padding: 10px 15px;
        @media(min-width: $tabletMin){
            padding: 10px 30px;
        }
    }
    .mb_background-td{
        background-color: $veryLightBGColor;
    }
    .mb_table-heading-inline{
        display: flex;
        flex-wrap: wrap;
        figure{
            max-width: 147px;
        }
        .mb_table-heading{
                padding: 30px;
                margin: auto 0;
            a{
                color: $themeColor;
                font-size: 16px;
                font-weight: $regular;
                text-decoration: underline;
                &:hover{
                    color: $themeColor;
                    text-decoration: none;
                }
            }
        }
    }
}

.mb_contact-page-section{
	.mb_contact-page-row{
		margin-top: 60px;
	}
	.in_ul-contact{
		padding: 0;
        li:first-child{
            img{
                margin-right: 39px;
            }
        }
		li{
			display: flex;
			margin-bottom: 30px;
			img{
				max-width: 30px;
				max-height: 30px;
				margin-right: 30px;
			}
			.mb_paragraph-3{
				margin-bottom: 0;
			}
		}
	}
}

.mb_pricelist-heading-section{
    padding: 245px 0 10px 0;
    .mb_pricelist-button-row{
        margin: 15px 0 10px 0;
    }
    .mb_button-gold-fill{
        margin: 15px;
        min-width: 188px;
        @media(min-width: $tabletMin){
            margin: 0 15px;
        }
    }
    .mb_button{
        margin: 15px 15px 0 15px;
        min-width: 188px;
        @media(min-width: $tabletMin){
            margin: 0 15px;
        }
    }
    .container{
        .mb_paragraph{
            max-width: 774px;
            margin: 0 auto 30px auto;
        }
        .mb_paragraph-2{
            max-width: 774px;
            margin: 0 auto;
        }
        .mb_paragraph-7{
            max-width: 774px;
            margin: 0 auto 30px auto;
        }
        .mb_email{
            color: $themeColor;
        }
        .mb_telephone{
            color: $darkColor;
        }
    }
}

.mb_pricelist-table-section{
    padding: 70px 0 10px 0;
    .mb_heading-9{
        margin-bottom: 30px;
    }
    .mb_table{
        tr:first-child{
            height: 60px;
        }
        .mb_th-gold{
            font-size: 12px;
            font-weight: $bold;
            color: $themeColor;
            padding: 0 0 10px 15px;
        }
        .mb_td-price{
           padding: 0 0 15px 15px; 
        }
    }
    .mb_margin-bottom-20{
        margin-bottom: 20px;
    }
    /*.mb_table-1{
        tr:first-child{
            height: 43px;
            @media(min-width: $laptopMin){
                height: 27px;
            }
            th{
                font-size: 12px;
                font-weight: $bold;
                color: $themeColor;
                padding: 0 0 10px 0;
            }
        }
        th:first-child{
            padding: 0 15px 20px 0;
        }
    }*/
}

.mb_free-contract-table-section{
    padding: 100px 0 70px 0;
    .mb_heading-9{
        margin-bottom: 30px;
        a{
            color: $themeColor;
        }
    }
    .mb_border-bottom-center{
        margin: 0 auto 60px auto;
    }
}

.mb_joint-practice-commitee-section{
    padding: 20px 0 35px 0;
    .mb_border-bottom{
        margin: 60px 0;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    .mb_table-col-3{
        width: 21.66666%;
        @media(min-width: $laptopMin){
            width: 25%;
        }
    }
    .mb_table-col-2{
        width: 20%;
        @media(min-width: $laptopMin){
            width: 16.66666%;
        }
    }
}

.mb_insurance-section{
    padding: 20px 0 0 0;
    img{
        max-width: 357px;
        margin-bottom: 15px;
    }
    p:last-child{
        margin: 0;
    }
}

.mb_health-safety-section{
    padding: 0;
}

.mb_health-safety-list{
    padding: 20px 0 20px 0;
}

.mb_health-safety-secondary-heading-section{
    padding: 60px 0 30px 0;
    .container{
        max-width: 774px;
    }
}

.mb_health-safety-table-section{
    padding: 20px 0 35px 0;
    .mb_border-bottom{
        margin: 60px 0;
    }
    ul{
        padding: 0;
        margin: 0;
    }
}

.mb_telephone-numbers-table-section{
    padding: 20px 0 35px 0;
    ul{
        padding: 0;
        margin: 0;
    }
    .mb_table-col-3{
        width: 21.66666%;
        @media(min-width: $laptopMin){
            width: 25%;
        }
    }
    .mb_table-col-2{
        width: 20%;
        @media(min-width: $laptopMin){
            width: 16.66666%;
        }
    }
    .mb_telephone-number{
        text-decoration: none;
    }
    a:hover{
        text-decoration: none;
    }
}

.mb_faq-section{
    ul{
        padding: 0;
    }
    .accordion{
        button{
            border: none;
            background-color: transparent;
            margin: 0;
            padding: 0;
            text-align: left;
            .mb_read-more-link{
                font-size: 16px;
            }
        }
        button:hover{
            .mb_read-more-link{
                text-decoration: none;
            }
        }
    }
    .mb_hide-read-more-link{
        .mb_read-more-link{
            display: none;
        }
    }
    .mb_heading-3-1{
        margin-bottom: 10px;
    }
    .mb_paragraph-2{
        font-size: 20px;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }
    .mb_border-bottom-light{
        margin: 30px 0;
    }
}

.mb_page-not-found-section{
    padding: 245px 0 100px 0;
}

.mb_thank-you-section{
    padding: 245px 0 100px 0;
    min-width: 550px;
    max-width: 600px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - 110px);
    .mb_thank-you-content{
        border: 1px solid $themeColor;
        padding: 75px;
        box-shadow: 0px 10px 60px #0000004D;
        .mb_button-gold-fill{
            margin-top: 30px;
            min-width: 140px;
        }
    }
}

.mb_infrastructure-section{
    text-align: center;
}

._member-showcase-section{
    .col{
        margin-bottom: 30px;
    }
    ._item{
        width: 100%;
        text-align: center;
        color: $darkColor;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 30px;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transition(0.15s);
        &:hover{
            color: $themeColor;
        }
        img{
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
}

.mb_showroom-heading-section{
    ._logo{
        margin-bottom: 30px;
    }
}

._member-showcase-projects-section{
    .col{
        margin-bottom: 30px;
    }
    ._item{
        background-position: center;
        background-size: cover;
        display: block;
        height: 240px;
        position: relative;
        ._caption{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: $themeColor2;
            padding: 10px;
            p{
                margin-bottom: 0;
                color: #fff;
                strong{
                    font-weight: 600;
                }
                i{
                    color: $themeColor;
                    font-weight: 400;
                }
            }
        }
    }
}

._member-showcase-project-gallery-section{
    .col{
        margin-bottom: 30px;
    }
}

._em-op-ul{
    padding-left: 0;
    li{
        padding: 8px 0;
        a{
            color: $themeColor2;
            font-size: 20px;
            font-weight: 600;
            text-decoration: underline;
            position: relative;
            &:before{
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                transform: rotate(45deg) translateY(-50%);
                border-right: 1px solid $themeColor;
                border-top: 1px solid $themeColor;
                position: absolute;
                left: -15px;
                top: 50%;
            }
        }
    }
}