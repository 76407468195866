@mixin transition($speed){
    -webkit-transition: ease-in-out $speed;
    -moz-transition: ease-in-out $speed;
    -ms-transition: ease-in-out $speed;
    transition: ease-in-out $speed;
}

@mixin cubicBeizer($speed){
    -webkit-transition: cubic-bezier(.76,.1,.23,.87) $speed;
    -moz-transition: cubic-bezier(.76,.1,.23,.87) $speed;
    -ms-transition: cubic-bezier(.76,.1,.23,.87) $speed;
    transition: cubic-bezier(.76,.1,.23,.87) $speed;
}

@mixin delay($duration){
    -webkit-transition-delay: $duration;
    -moz-transition-delay: $duration;
    -ms-transition-delay: $duration;
    transition-delay: $duration;
}

@mixin scale($ratio){
    -webkit-transform: scale($ratio);
    -moz-transform: scale($ratio);
    -ms-transform: scale($ratio);
    transform: scale($ratio);
}

@mixin mb_heading-3{
    color: $themeColor;
    font-size: 30px;
    font-weight: $bold;
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
}

@mixin overlay{
    background-color: $lightColor;
    opacity: 0.9;
}

@mixin dropshadow{
    -webkit-box-shadow: 0px 10px 30px -20px rgba(0,0,0,0.7);
    -moz-box-shadow: 0px 10px 30px -20px rgba(0,0,0,0.7);
    box-shadow: 0px 10px 30px -20px rgba(0,0,0,0.7);
}