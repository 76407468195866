.mb_header{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 145px;
    @include transition(0.25s);
    .mb_item-left{
        a{
            position: fixed;
            top: 0;
            img{
                width: 148px;
                @include transition(0.25s);
            }
        }      
    }
    .mb_header-top-home{
        opacity: 0.9;
        &.mb_transformed{
            opacity: 1;
        }
    }
    .mb_header-top{
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        background-color: $themeColor2;
        height: 95px;
        @include transition(0.25s);
        .mb_item{
            margin: 27px 0;
            @include transition(0.25s);
        }
        .container{
            height: 100%;
            display: flex;
            flex-direction: row-reverse; 
        }
        .mb_item-center{
            .mb_ul-contact{
                display: none;
                @media(min-width: $laptopMin){
                    display: flex;
                    margin: 0;
                    padding: 0;
                    align-items: flex-start;
                }
                li{
                    margin: 0 20px;
                    display: flex;
                    img{
                        width: 30px;
                        margin-right: 10px;
                    }
                    .mb_heading-8{
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    .mb_paragraph{
                        color: white;
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .mb_item-right{
            .mb_ul-social{
                display: flex;
                margin: 0;
                padding-top: 5px;
                padding-left: 0;
                a{
                    padding-left: 10px;
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
                @media(min-width: $desktopMin){
                    padding-left: 30px;
                }
            }
        }
        &.mb_transformed{
            height: 75px;
            .mb_item{
                margin: 17px 0;
            }
        }
    }
    .mb_header-bottom{
        position: fixed;
        z-index: 1;
        left: 0;
        top: 95px;
        width: 100%;
        background-color: $themeColor;
        height: 50px;
        @include transition(0.25s);
        .container{
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .mb_item-center{
            display: none;
        }
        @media(min-width: $laptopMin){
            .mb_item-left{
                width: 218px;
            }
            .mb_item-center{
                display: block;
                width: 100%;
            } 
        }
         &.mb_transformed{
            top: 75px;
        }
    }
    
    
    &.mb_transformed{
        background-color: $themeColor;
        height: 125px;
        .mb_item-left{
            a{
                img{
                    width: 128px;
                }
            }      
        }
    }
    .mb_menu-button{
        padding: 10px 0 0 0;
        background-color: transparent;
        border: none;
        outline: none;
        z-index: 3;
        @media(min-width: $laptopMin){
            display: none;
        }
    }
    .mb_flyopen-menu{
        position: fixed;
        top: 0;
        z-index: 2;
        width: 100%;
        left: 100%;
        height: 100vh;
        background-color: white;
        @include cubicBeizer(0.6s);
        @include delay(0.25s);
        overflow: auto;
        @media (min-width: $tabletMin){
            @include cubicBeizer(0.8s);
        }
        @media (min-width: $laptopMin){
            @include cubicBeizer(1s);
        }
        @media (min-width: $desktopMin){
            @include cubicBeizer(1.1s);
        }
        &.open{
            left: 0;
        }
    }
}

.mb_widescreen-nav{
    height: 100%;
    ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        li{
            height: 100%;
            .mb_link-color{
                color: #000000;
            }
            a{
                font-size: 14px;
                height: 100%;
                text-transform: uppercase;
                padding: 14px 8px;
                white-space: nowrap;
                &.mb_active{
                    background-color: $themeColor2;
                    color: white;                  
                }
                // @media(min-width: $desktopMin){
                //     padding: 14px 10px;
                // }
            }
            &:last-child{
                a{
                    padding-right: 0;
                }
            }
            .btn{
                border-radius: 0;
            }
            .btn-primary{
                color: white;
                font-weight: 600;
                font-size: 14px;
                height: 100%;
                text-transform: uppercase;
                padding: 14px 10px;
                background-color: transparent;
                border: none;
                border-radius: none;
                @media(min-width: $desktopMin){
                    padding: 14px 20px;
                }
            }
            .btn-primary:active:hover,
            .btn-primary.active:hover,
            .open > .dropdown-toggle.btn-primary:hover,
            .btn-primary:active:focus,
            .btn-primary.active:focus,
            .open > .dropdown-toggle.btn-primary:focus,
            .btn-primary:active.focus,
            .btn-primary.active.focus,
            .open > .dropdown-toggle.btn-primary.focus {
                    background-color: transparent;
            }
            /*.btn-primary.dropdown-toggle::after{
                width: 10px;
                height: 10px;
                display: inline-block;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                border-right: 3px solid $themeColor;
                border-bottom: 3px solid $themeColor;
                @include transition(0.25s);
                @include delay(0.25s);
                margin: 3px 5px;
            }*/
            .show > .btn-primary.dropdown-toggle{
                background-color: transparent;
                border: none;
            }
            .dropdown-menu{
                background-color: $themeColor2;
                color: white;
                font-size: 12px;
                transform: translate3d(0px, 49px, 0px) !important;
                border-radius: 0;
                a{
                    color: white;
                    font-size: 12px; 
                    font-weight: $regular;
                    padding: 5px 20px;
                    text-transform: capitalize;
                    display: block ruby;
                }
            }
        }
    }
}

.mb_mobile-side-menu{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    ul{
        padding-left: 0;
        text-align: center;
        margin-bottom: 0;
    }
    .mb_ul-menu{
        margin-top: 60px;
        .mb_mobile-nav-link{
            .mb_link-color{
                color: $themeColor2;
            }
            a{
                padding: 5px 0;
                font-size: 24px;
                font-weight: $bold;
                color: $themeColor;
            }
        }
        .mb_mobile-nav-accordion{
            
                button{
                    border: none;
                    color: $themeColor;
                    font-size: 24px;
                    font-weight: $bold;
                    background-color: transparent;
                    margin-left: 6px;
                }
                a{
                    font-size: 16px;
                    color: $lightTextColor;
                    font-weight: $semiBold;
                }

            
        }
    }
    .mb_ul-social{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        a{
            padding: 5px;
            img{
                width: 30px;
                height: 30px;
            }
        }
        
    }
    .mb_ul-contact{
        margin-top: 25px;
        display: flex;
        justify-content: center;
        li{
            font-weight: $regular;
            font-size: 16px;
            color: $themeColor2;
            margin: 0 10px; 
            .mb_heading-8{
                color: $themeColor; 
                margin-top: 20px;
                margin-bottom: 0;
            }
            a{
                padding: 0;
                color: inherit;
            }
        }
        li:last-child{
            img{
                margin: 4px 0;
            }
        }
    }
    @media(min-width: $laptopMin){
        display: none;
    }
}

.mb_footer-menu-mobile{
    margin-top: 30px;
    background-color: #1B2026;
    padding: 15px;
    color: white;
    a{
        color: white;
        font-size: 12px;
    }
}

.mb_footer{
    padding-top: 50px;
    background-color: $themeColor2;
    .mb_logo-footer{
        width: 135px;
        margin-bottom: 40px;
        @media(min-width: $tabletMin){
            margin-bottom: 5px;
        }
    }
    .mb_heading-3{
        margin-bottom: 20px;
        font-size: 18px;
        @media(min-width: $desktopMin){
            font-size: 20px;
        }
    }
    .mb_paragraph{
        color: white;
    }
    .mb_ul-contact{
        padding-left: 0;
        li{
            /*padding: 8px 0;*/
            margin-bottom: 22px;
            a{
                color: white;
                font-weight: $regular;
            }
        }
    }
    .mb_reg-nr{
        font-size: 12px;
        color: white;
        margin: 8px 0 40px 0;
        @media(min-width: $tabletMin){
            margin: 8px 0 40px 0;
        }
        @media(min-width: $laptopMin){
            margin: 8px 0 0 0;
        }
    }
    .mb_ul-menu{
        padding-left: 0;
        li{
            a{
                color: white;
                font-weight: $regular;
                padding: 5px;
            }
            img{
                margin-bottom: 10px;
            }
            .mb_no-padding{
                padding: 0;
            }
        }
    }
    .mb_button{
        margin-top: 15px;
    }
    @media(min-width:$tabletMin){
        padding-top: 75px;
    }
    @media(min-width:$laptopMin){
        padding-top: 100px;
    }
}

.mb_bottom-footer{
    background-color: #1B2026;
    margin-top: 50px;
    height: 50px;
    padding: 13px;
    font-size: 14px;
    .mb_inner{
        text-align: center;
        color: white;
    }
    a{
        color: white;
    }
}

.mb_table{
    line-height: 1.3;
    min-width: 774px;
    margin-bottom: 60px;
    tr:first-child{
        th{
            vertical-align: bottom;
        }
    }
    th{
        border-top: none;
        font-size: 14px;
        font-weight: $bold;
        color: $darkColor;
        @media(min-width: $tabletMin){
            font-size: 16px;
        }
        .mb_border-bottom-light{
             margin: 10px 0 10px 0;
            @media(min-width: $tabletMin){
                margin: 10px 0 10px 0;
            }
        }
    }
    td{
        padding-bottom: 10px;
        font-size: 12px;
        color: $darkColor;
        border-top: none;
        @media(min-width: $tabletMin){
            font-size: 16px;
        }
    }
    a{
        color: $darkColor;
        text-decoration: underline;
    }
    .mb_td-height{
        height: 100%;
    }
    .mb_table-col-padding-right{
        padding: 0 5px 5px 0;
        @media(min-width: $laptopMin){
            padding: 0 15px 5px 0;
        }
    }
    .mb_table-col-padding{
        padding: 0 5px 5px 5px;
        @media(min-width: $laptopMin){
            padding: 0 15px 5px 15px;
        }
    }
    .mb_table-col-padding-left{
        padding: 0 0 5px 5px;
        @media(min-width: $laptopMin){
            padding: 0 0 5px 15px;
        }
    }
}
.mb_table-1{
    height: 100%;
    th{
        padding: 0 0 20px 0;
        font-size: 14px;
        font-weight: $bold;
        color: $darkColor;
        vertical-align: top;
        @media(min-width: $tabletMin){
            font-size: 14px;
        }
        @media(min-width: $laptopMin){
            font-size: 16px;
        }
        .mb_border-bottom-light{
             margin: 10px 0 10px 0;
            @media(min-width: $tabletMin){
                margin: 10px 0 15px 0;
            }
        }
    }
    td{
        padding: 0 0 20px 0;
        font-size: 14px;
        vertical-align: top;
         @media(min-width: $tabletMin){
            font-size: 14px;
        }
        @media(min-width: $laptopMin){
            font-size: 16px;
        }
    }
    .mb_paragraph-2{
        font-size: 12px;
        @media(min-width: $tabletMin){
            font-size: 14px;
        }
        @media(min-width: $laptopMin){
            font-size: 16px;
        }
    }
}
.mb_table-col-12{
    width: 100%;
}
.mb_table-col-8{
    width: 66.66666%;
}
.mb_table-col-7{
    width: 58.33333;
}
.mb_table-col-6{
    width: 50%;
}
.mb_table-col-5{
    width: 41.66666%;
}
.mb_table-col-4{
    width: 33.33333%;
}
.mb_table-col-3{
    width: 25%;
}
.mb_table-col-2{
    width: 16.66666%;
}



