.mb_heading-1{
    color: white;
    font-size: 22px;
    font-weight: $bold;
    margin-bottom: 30px;
    @media(min-width: $tabletMin){
        font-size: 40px;
    }
    @media(min-width: $laptopMin){
        font-size: 30px;
    }
    @media(min-width: $desktopMin){
        font-size: 30px;
        line-height: 40px;
    }
}

.mb_heading-1-2{
    color: $themeColor2;
    font-size: 30px;
    font-weight: $bold;
    margin-bottom: 15px;
    text-transform: uppercase;
    @media(min-width: $tabletMin){
        font-size: 40px;
    }
    @media(min-width: $laptopMin){
        font-size: 40px;
    }
    @media(min-width: $desktopMin){
        font-size: 40px;
        line-height: 40px;
    }
}

.mb_heading-1-2-2{
    color: $themeColor2;
    font-size: 30px;
    font-weight: $bold;
    margin-bottom: 15px;
    @media(min-width: $tabletMin){
        font-size: 40px;
    }
    @media(min-width: $laptopMin){
        font-size: 40px;
    }
    @media(min-width: $desktopMin){
        font-size: 40px;
        line-height: 40px;
    }
}

.mb_heading-2{
    color: $themeColor;
    font-size: 4    w0px;
    font-weight: $bold;
    margin-bottom: 30px;
    @media(min-width: $tabletMin){
        font-size: 40px;
    }
    @media(min-width: $laptopMin){
        font-size: 40px;
    }
    @media(min-width: $desktopMin){
        font-size: 40px;
    }
}

.mb_heading-2-1{
    color: $themeColor;
    font-size: 60px;
    font-weight: $bold;
    margin: 0 30px 0 0;
    line-height: 1;
    @media(min-width: $desktopMin){
        font-size: 60px;
    }
}

.mb_heading-3{
    color: white;
    font-size: 20px;
    font-weight: $bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media(min-width: $desktopMin){
        font-size: 20px;
    }
}

.mb_heading-3-1{
    color: $themeColor;
    font-size: 20px;
    font-weight: $bold;
    margin-bottom: 15px;
}

.mb_heading-3-2{
    color: $themeColor2;
    font-size: 20px;
    font-weight: $bold;
    /*margin-bottom: 22px;*/
}

.mb_heading-4{
    color: $themeColor;
    font-size: 25px;
    font-weight: $bold;
    font-style: italic;
    margin: 60px 0;
    padding: 30px 0;
}

.mb_heading-5{
    color: white;
    font-size: 20px;
    font-weight: $bold;
}

.mb_heading-5-1{
    color: $themeColor2;
    font-size: 20px;
    font-weight: $regular;
}

.mb_heading-6{
    color: #000;
    font-size: 17px;
    font-weight: $semiBold;
    text-transform: uppercase;
}

.mb_heading-6-1{
    color: #000;
    font-size: 14px;
    font-weight: $semiBold;
    text-transform: uppercase;
}

.mb_heading-7{
    color: #000;
    font-size: 15px;
    font-weight: $regular;
}

.mb_heading-9{
    color: $darkColor;
    font-size: 16px;
    font-weight: $bold;
}

.mb_heading-8{
    color: white;
    font-size: 16px;
    font-weight: $bold;
    text-transform: uppercase;
}

.mb_paragraph{
    color: $darkColor;
    font-size: 16px;
    font-weight: $regular;
}
.mb_paragraph-2{
    color: $lightTextColor;
    font-size: 16px;
    font-weight: $regular;
    font-style: italic;
}
.mb_paragraph-3{
    color: $lightTextColor;
    font-size: 16px;
    font-weight: $regular;
}
.mb_paragraph-4{
    color: #000;
    font-size: 14px;
    font-weight: $extraLight;
}
.mb_paragraph-5{
    color: $lightTextColor;
    font-size: 12px;
    font-weight: $extraLight;
}
.mb_paragraph-6{
    color: #000;
    font-size: 17px;
    font-weight: $semiBold;
}
.mb_paragraph-7{
    color: $themeColor;
    font-size: 16px;
    font-weight: $semiBold;
}
.mb_paragraph-8{
    color: #000;
    font-size: 12px;
    font-weight: $semiBold;
}
.mb_innerHTML{
    color: $textColor;
    font-size: 16px;
    font-weight: $regular;
    p{
        color: $textColor;
        font-size: 16px;
        font-weight: $regular;
    }
    strong{
        color: $themeColor;
        font-size: 16px;
        font-weight: $semiBold;
    }
    h1, h2, h3, h4{
        color: $themeColor;
        font-size: 30px;
        font-weight: $semiBold;
        margin-bottom: 30px;
        line-height: 1.5;
        @media(min-width: $desktopMin){
            font-size: 40px;
        }
    }
    a{
        color: $themeColor2;
        font-size: 16px;
        font-weight: $bold;
    }
}
.mb_button{
    color:$themeColor2;
    background-color:transparent;
    font-weight: $bold;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 15px;
    border: 1px solid $themeColor;
    min-width: 162px;
    height: 50px;
    display: inline-block;
    width: auto;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    @include transition(0.25s);
    @include delay(0.25s);
    &:hover{
        background-color:$themeColor;
        color: white;
    }
}

.mb_button-white{
    color:$white;
    background-color:transparent;
    font-weight: $bold;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 15px;
    border: 1px solid $themeColor;
    min-width: 162px;
    height: 50px;
    display: inline-block;
    width: auto;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    @include transition(0.25s);
    @include delay(0.25s);
    &:hover{
        background-color:$themeColor;
        color: white;
    }
}

.mb_button-gold-fill{
    color:$white!important;
    background-color:$themeColor;
    font-weight: $bold;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 15px;
    border: 1px solid $themeColor;
    min-width: 162px;
    height: 50px;
    display: inline-block;
    width: auto;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    @include transition(0.25s);
    @include delay(0.25s);
    &:hover{
        background-color:white;
        color: $themeColor!important;
    }
}

.mb_button-fill{
    color: $themeColor;
    background-color: white;
    text-align: center;
    font-weight: $bold;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 15px;
    border: 1px solid white;
    min-width: 140px;
    height: 50px;
    display: inline-block;
    width: auto;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    @include transition(0.25s);
    @include delay(0.25s);
    &:hover{
        background-color:$themeColor;
        color: white;
    }
}

.mb_tabs-button{
    color:$textColor;
    background-color:transparent;
    font-weight: $regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 0;
    border: 1px solid $themeColor;
    height: 37px;
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include transition(0.25s);
    @include delay(0.25s);
    border-radius: 0!important;
    &:hover{
        background-color:$themeColor;
        outline:none;
        color: white;
    }
    &:active{
        background-color:$themeColor;
        color: white;
        outline:none;
    }
    &:focus{
        background-color:$themeColor;
        color: white;
        outline:none;
    }
    &.mb_active{
        background-color:$themeColor!important;
        color: white;
        outline:none;
    }
    &.active{
        background-color:$themeColor!important;
        color: white;
        outline:none;
    }
}

.mb_dropdown-button{
    color:$textColor;
    background-color:transparent;
    font-weight: $regular;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid $themeColor;
    // display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;
    @include transition(0.25s);
    @include delay(0.25s);
    border-radius: 0!important;
    line-height: 0.9rem;
    &::after{
        position: absolute;
        right: 10px;
        top: 45%;
    }
    &:hover{
        background-color:$themeColor!important;
        outline:none;
        color: white;
        border: 1px solid $themeColor;
    }
    &:active{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
    &:focus{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
    &.mb_active{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
    &.active{
        background-color:$themeColor!important;
        color: white;
        outline:none!important;
        border: 1px solid $themeColor;
    }
}

.mb_read-more-link{
    color: $themeColor2;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    &:hover{
        color: $themeColor2;
    }
}

.mb_unordered-list{
    padding-left: 0;
    margin-bottom: 0;
    li{
        color: white;
        font-weight: $bold;
        font-size: 16px;
        a{
            color: white;
            font-weight: $bold;
            font-size: 16px;
            
        }
    }
}

.mb_heading-section{
    padding: 225px 0 20px 0;
    @media(min-width: $laptopMin){
        padding: 245px 0 20px 0;
    }
    .container{
        .mb_paragraph-2{
            max-width: 774px;
            margin: 20px auto; 
        }
         .mb_paragraph-div {
/*            text-align: left;*/
            max-width: fit-content;
            margin: 0 auto;
        }
    }
    .mb_heading-link{
        color: $themeColor;
    }
}

.mb_secondary-heading-section{
    padding: 80px 0 30px 0;
    @media(min-width: $laptopMin){
        padding: 80px 0 30px 0;
    }
    .container{
        max-width: 774px;
    }
}

.mb_signup-section{
    background-color: $themeColor;
    height: 110px;
    padding: 25px 0;
    color: white;
    article{
        display: flex;
        justify-content: space-between;
    }
    .mb_heading-2{
        color: white;
        margin-bottom: 0;
    }
    .mb_paragraph{
        display: none;
        color: white;
        font-weight: $semiBold;
        margin: 18px 0;
        @media(min-width: $laptopMin){
            display: block;
            font-size: 14px;
        }
        @media(min-width: $desktopMin){
            font-size: 16px;
        }
    }
    .mb_button-fill{
        margin: 5px 0;
    }
}

.mb_slider-item{
    width: 100%;
}
.mb_slider-dots{
    position: absolute;
    bottom: 20px;
    display: block;
    width: 100%;
    padding: 20px;
    margin: 0;
    list-style: none;
    text-align: center;
    li{
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        &.slick-active{
            button{
                background-color: white;
            }
        }
        button{
            display: block;
            width: 15px;
            height: 17px;
            color: transparent;
            cursor: pointer;
            border: 1px solid #fff;
            background-color: transparent;
            border-radius: 20px;
            @include transition(0.15s);
            @include delay(0.15s);
            /* Hide the text. */
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            &:hover{
                background-color: white;
            }
        }
    }
}

.mb_menu-button{
    .mb_inner{
        position: relative;
        width: 30px;
        height: 30px;
        span{
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: white;
            left: 0;
            transform:translateY(-50%);
            @include transition(0.15s);
            @include delay(0.15s);
            &:nth-of-type(1){
                top: 20%;
            }
            &:nth-of-type(2){
                top: 50%;
            }
            &:nth-of-type(3){
                top: 80%;
                width: 60%;
                left: 40%;
            }
        }
    }    
    &.open{
        position: absolute;
        right: 65px;
        @include transition(0.25s);
        span{
            background-color: $themeColor;
            &:nth-of-type(1){
                top: 50%;
                transform: rotate(45deg);
            }
            &:nth-of-type(2){
                transform: rotate(-45deg);
            }
            &:nth-of-type(3){
                opacity: 0;         
            }
        }
    }
}

.mb_carousel-item{
    padding: 30px;
    text-align: center;
    figure{
        height: 200px;        
    }
    img{
        height: 100%;
        max-width: 100%;
        width: auto;   
        // max-height: 100%;  
        margin: 15px auto 30px auto;  
    }
}

.mb_carousel{
    width: 80%;
    margin: 0 auto;
    .mb_carousel-button{
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 40px;
        height: 50px;
        background-color: $themeColor;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before{
            display: none;
        }
        img{
            width: 12px;
        }
    }
    .mb_next-button{
        right: -31px;
    }
    .mb_prev-button{
        left: -31px;
    }
}

.mb_form{
    .mb_border-bottom-left{
        margin-bottom: 60px;
    }
    .mb_form-control{
        width: 100%;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-border: none;
        -webkit-border-radius: 0;
        -webkit-outline: none;
        -webkit-appearance:none;
        padding: 13px 15px;
        color: $darkColor;
        font-size: 14px;
        background-color: $veryLightBGColor;
        &::placeholder{
            color: $lightTextColor;
            opacity: 1;
        }
    }

    .mb_error{
        color: red;
        font-size: 16px;
        font-weight: $bold;
        margin-bottom: 30px;
    }
    textarea{
        height: 200px;
        resize: none;
    }
    .mb_button{
        min-width: 140px;
    }
}

.mb_hr{
    width: 100%;
    margin: 60px 0 30px 0;
    border-top: 1px solid $lightBorderColor;
}

.mb_background-image-item{
    width: 100%;
    height: calc(100vh - 110px);
    background-position: center;
    background-size: cover;
    position: relative;
    .mb_inner{
        position: absolute;
        top: 60%;
        transform: translateY(-50%);
        max-width: 510px;
        width: calc(100% - 30px);
        background-color: $themeColor2;
        opacity: 0.9;
        padding: 40px;
        @media(min-width: $laptopMin){
            max-width: 578px;
        }
        .mb_heading-1{
            margin-bottom: 0;
        }
    }
}

.mb_arrow-down{
    width: 10px;
    height: 10px;
    display: inline-block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-right: 3px solid $themeColor;
    border-bottom: 3px solid $themeColor;
    @include transition(0.25s);
    @include delay(0.25s);
    margin: 3px 5px;
}

.mb_change-arrow-up{
    .mb_arrow-down{
        width: 10px;
        height: 10px;
        display: inline-block;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        border-right: none;
        border-left: 3px solid $themeColor;
        border-bottom: none;
        border-top: 3px solid $themeColor;
        @include transition(0.25s);
        @include delay(0.25s);
        margin: 0 5px;
    }
}

.mb_loader{
    text-align: center;
    padding: 30px 0;
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: $themeColor;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
.mb_image-item{
    cursor: pointer;
    img{
        width: 100%;
    }
}

.mb_next-arrow{
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 40px;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    @include transition(0.25s);
    @include delay(0.25s);
    @media(min-width: $laptopMin){
        right: 30px;
    }
}

.mb_prev-arrow{
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 40px;
    z-index: 1;
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    @include transition(0.25s);
    @include delay(0.25s);
    @media(min-width: $laptopMin){
        left: 30px;
    }
}

.mb_pagination{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 60px;
    .page-item{
        /*width: 30px;
        height: 30px;*/
        margin: 0 5px;
        .page-link{
            color: #808495;
            border-radius: 0;
            line-height: 1;
            font-size: 16px;
            padding: 6px 10px;
            margin: 0;
        }
    }
    .page-item.active .page-link{
        background-color: $themeColor;
        border-color: $themeColor;
        color: white;
    }
}

.mb_search-form{
    display: flex;
    margin-bottom: 60px;
    .mb_form-input{
        width: calc(100% - 155px);
        @media(min-width: $tabletMin){
            width: calc(100% - 200px);
        }
        ul{
            margin: 0;
            padding: 0;
            position: absolute;
            background-color: white;
            box-shadow: 0px 10px 60px #0000004D;
            width: calc(100% - 185px);
            z-index: 1;
            overflow: auto;
            max-height: 290px;
            @media(min-width: $tabletMin){
                width: calc(100% - 230px);
            }
            li{
                padding: 0 15px;
                &:hover{
                    color: $themeColor;
                    cursor: pointer;
                }
            }
        }
    }
    .mb_button{
        min-width: 140px;
        height: 47px;
        padding: 10px 30px;
        position: absolute;
        bottom: 60px;
        right: 15px;
        @media(min-width: $tabletMin){
            min-width: 170px;
        }
    }
}

.mb_search-form-date{
    display: flex;
    margin-bottom: 30px;
    .mb_form-input{
        width: calc(100% - 155px);
        @media(min-width: $tabletMin){
            width: calc(100% - 200px);
        }
    }
    .mb_button{
        min-width: 140px;
        height: 47px;
        padding: 10px 30px;
        position: absolute;
        bottom: 30px;
        right: 15px;
        @media(min-width: $tabletMin){
            min-width: 170px;
        }
    }
}

.mb_search-form-date-news{
    margin-bottom: 30px;
    .mb_form-input{
        margin-top: 30px;
        @media(min-width: $tabletMin){
            margin-top: 0;
        }
    }
    .mb_button{
        min-width: 140px;
        height: 47px;
        padding: 10px 30px;
        position: relative;
        bottom: 0;
        margin-top: 30px;
        @media(min-width: $tabletMin){
            min-width: 170px;
        }
    }
}

.mb_search-form-left{
    /*display: flex;
    margin-bottom: 30px;*/
    min-height: 70px;
        @media(min-width: $tabletMin){
            min-height: 109px;
        }
    .mb_button{
        height: 47px;
        padding: 10px 30px;
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-bottom: 30px;
        @media(min-width: $tabletMin){
            position: absolute;
            bottom: 30px;
            left: 15px;
            width: calc(100% - 30px);
            margin-bottom: 0;
        }
    }
    .mb_button-gold-fill{
        height: 47px;
        padding: 10px 30px;
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        min-width: 140px;
        margin-bottom: 30px;
        @media(min-width: $tabletMin){
            position: absolute;
            bottom: 30px;
            left: 15px;
            width: calc(100% - 30px);
            margin-bottom: 0;
        }
    }
}
.mb_search-all{
    .mb_button-search-all{
        color: $themeColor;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        padding: 0 4px;
        &:hover{
            color: $themeColor;
            text-decoration: none;
        }
    }
}

.mb_body-article-section{
    padding: 20px 0 20px 0;
    .mb_body-article{
        h1, h2, h3, h4{
            line-height: 1.2;
        }
        ul{
            padding: 0;
        }
    }
}

._flex{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    .mb_button-gold-fill{
        margin: 0 30px 30px 0;
        padding: 14px 16px;
    }
    @media(min-width: $tabletMin){
        .mb_button-gold-fill{
            margin: 0;
        }
    }
}